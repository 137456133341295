<template>
  <div id="login" style="height: 100%; background-color: rgba(255, 255, 255, 0); margin: 0 auto;padding: 0">
    <form action="#" style="height: 100%; display: flex; align-items: center">
      <div class="main-container" style=" background-color: rgba(255, 255, 255, 0)">
        <div class="main" style="opacity: 1 !important; margin: 20px 20px">
          <h3>Смена пароля</h3>
      <span>Временный пароль</span>
      <br />
      <input class="input login" type="text" autocomplete="off" id="tempPassword" v-model="tempPassword" :class="{'invalid': invalidTempPassword}"/>
      <br/>
      <br/>
      <br/>
      <span>Введите новый пароль</span>
       <br />
      <input class="input login pass" :type="passwordVisible ? 'text' : 'password'" autocomplete="new-password" id="newPassword" v-model="newPassword"  placeholder="новый пароль" :class="{'invalid': invalidNewPassword}"/>
          <span v-show="newPassword" class="glyphicon eye" @click="passwordVisible = !passwordVisible">&#128065;</span>
          <span v-show="newPassword && passwordVisible" class="glyphicon eye-cross" >&#47;</span>
      <br/>
      <span>Повторите новый пароль</span>
         <br />
      <input class=" input login pass" :type="passwordVisible ? 'text' : 'password'"  autocomplete="new-password" id="repeatPassword" v-model="repeatPassword" placeholder="Повторите пароль" :class="{'invalid': invalidRepeatPassword}"/>
          <span v-show="repeatPassword" class="glyphicon eye" @click="passwordVisible = !passwordVisible">&#128065;</span>
          <span v-show="repeatPassword && passwordVisible" class="glyphicon eye-cross" >&#47;</span>
      <br/>
      <input
              type="button"
              value="Изменить пароль"
              class="button login"
              id="registration"
              @click="resetPassword"
              style="background-color: #98D0E8; border: none"

      >
          <br/>

          <div ><a href="/signin"><h4 style="color:black; text-decoration: underline">вернуться на страницу входа</h4></a></div>
       </div>
      </div>
    </form>
    <br/>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'


  export default {
    name: "ResetPassword",
    data() {
      return {
        tempPassword: this.$route.params.tempPassword,
        newPassword: '',
        repeatPassword: '',
        passwordVisible: false
      }
    },
    computed: {
      invalidTempPassword() {
        return true
      },
      invalidNewPassword() {
        return true
      },
      invalidRepeatPassword() {
        return true
      }
    },
    methods: {
      ...mapActions(['showInfoAlert']),
      resetPassword() {
        this.showInfoAlert('пока не поддерживается')
        return true
      }
    }

  }
</script>

<style scoped>
  @media screen and (min-width: 800px) {
    .main-container {
      /*background: no-repeat center/100% url("../assets/volna_okean_vsplesk_54955_1280x1280.jpg");*/
      padding: 0;
    }
  }


  #login {
    background-color: aliceblue;
    background-size: cover;
    width: 100%;
    height: 100%;

  }

  .main-container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightsteelblue;
    padding: 0 20px;
    position: relative;
  }

  .main {
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    border-radius: 3rem;
    box-shadow: 0 0 8px -5px #000000;
    width: 750px;
    margin: auto 20px;
    padding: 10px;
    /*padding: 60px;*/
  }


  .button {
    cursor: pointer;
    user-select: none;
  }

  input.login {
    border-radius: 3rem;
    border: none;
    padding: 10px;
    text-align: center;
    outline: none;
    margin: 10px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 20px;
    width: 400px;
    max-width: 90vw;
  }

  input.pass {
    padding-left: 50px;
    padding-right: 50px

  }
</style>